import ReportLandingPage from '../../components/Revisor/ReportLanding'
import React from 'react'
import Pagewrapper from '../../components/PageWrapper'

export default function (props) {
    return (
        <Pagewrapper>
            <ReportLandingPage reportToken={props.params.reportToken} />
        </Pagewrapper>
    )
}
